import React from "react";

interface StudioContextInterface {
  nodeHovered: string | null;
  setNodeHovered: (node: string | null) => void;
}
const StudioContext = React.createContext<StudioContextInterface>({
  nodeHovered: null,
  setNodeHovered: () => {},
});

export default StudioContext;
