import { Badge, HStack, Text } from "@chakra-ui/react";

const MAP = {
  SendEmailSMTP: "Send Email (SMTP)",
  EmailSourceIMAP: "Mailbox (IMAP)",
  NewEmail: "Incoming Email (IMAP)",
  Instruct: "AI (ChatGPT)",
  InstructMultiInputs: "AI (ChatGPT)",
  FormatText: "Text Formatter",
  FormatTable: "Table Formatter",
  SheetsRead: "Read Sheets",
  SheetsWrite: "Write Sheets",
  Indexer: "Smart Search",
  Boolean: "True/False",
  Switch: "Condition",
  ToTable: "To Table",
  CsvToTable: "CSV to Table",
  DateTimeManipulate: "Manipulate Datetime",
  HtmlParser: "Parse HTML",
  ScrapePageDynamic: "Scrape Page",
  ScrapePageStatic: "Scrape Page (Static)",
  InstagramNewDm: "Incoming DM",
  InstagramSendDm: "Send DM",
  ScrapePagesDynamic: "Scrape Pages",
  GMailSource: "Inbox",
  GMailOutbox: "Outbox",
  GMailSend: "Send Email",
  GMailDraft: "Draft Email",
  GMailNewEmail: "Incoming Email",
  ShopifyOrder: "New Order (Shopify)",
  EtsyOrder: "New Order (Etsy)",
  SalesForce: "New Lead (Salesforce)",
  Hubspot: "New Lead",
  TableToTextSimple: "Table Formatter (Auto)",
  ValidateEmailAddress: "Validate Email",
  FormatDatetime: "Datetime Formatter",
  GCalendarRead: "Read Calendar (Google)",
  GCalendarWrite: "Write Calendar (Google)",
  NotifyMe: "Send Notification",
  NotifyMeDynamic: "Send Notification (Dynamic)",
  HubspotContactsRead: "Read Contacts",
  HubspotContactCreate: "Create Contact",
  HubspotCallCreate: "Create Call",
  HubspotCallsRead: "Read Calls",
  HubspotCompanyCreate: "Create Company",
  HubspotCompaniesRead: "Read Companies",
  HubspotDealCreate: "Create Deal",
  HubspotDealsRead: "Read Deals",
  HubspotTaskCreate: "Create Task",
  HubspotTasksRead: "Read Tasks",
  HubspotContactToCompany: "Assoc. Contact To Company",
  HubspotAuto: "Auto Sync ✨",
};

const getUserFriendlyName = (kls: string): string => MAP[kls] ?? kls;

const UserFriendlyBadge = ({
  color,
  textColor,
  kls,
  nameOverride,
}: {
  kls: string;
  nameOverride?: string;
}) => {
  return (
    <Badge fontWeight="800" fontSize="xs" bg={color} color={textColor}>
      <HStack>
        <Text>{getUserFriendlyName(nameOverride ?? kls)}</Text>
      </HStack>
    </Badge>
  );
};
export { getUserFriendlyName, UserFriendlyBadge };
