import { FlowsApi } from "typescript-axios";
import axios, { getAxiosParams } from "./lib/axios.config";
import React from "react";
import FormBuilt from "./form-built";


const FLOW_API = new FlowsApi(getAxiosParams(), undefined, axios);

const FormPreview = ({ initData, onSubmit, isPreview }) => {
    const [jsonSchema, setJsonSchema] = React.useState(null);
    React.useEffect(() => {
        // need to debounce
        if (initData.form.every((item) => item.name)) {
            FLOW_API.previewFormJsonApiV1FlowsFlowIdNodesPreviewFormPost(initData).then((response) => {
                setJsonSchema(response.data);
            });
        }
    }, [initData]);

    if (!jsonSchema) {
        return ""
    }

    return <FormBuilt
        isPreview={isPreview}
        buttonText={initData.button_text}
        buttonColor={initData.button_color}
        buttonOnSameRow={initData.button_on_same_row}
        jsonSchema={jsonSchema}
        onSubmit={onSubmit}
    />

}

export default FormPreview;