import { FormControl, FormLabel, Text } from '@chakra-ui/react';
import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'
import cronstrue from 'cronstrue';


interface CronWidgetProps {
    id: string;
    value: string;
    required: boolean;
    disabled: boolean;
    readonly: boolean;
    label: string;
    onChange: (value: string) => void;
}

const CronWidget = ({
    id,
    required,
    disabled,
    readonly,
    label,
    value,
    onChange,
}: CronWidgetProps) => {


    return (
        <FormControl id={id}>
            <FormLabel htmlFor={id}>
                {label}
                {required && <Text as="span" color='red.500' ms='1'>*</Text>}
            </FormLabel>
            <Cron
                className='my-cron'
                readOnly={readonly}
                disabled={disabled}
                periodicityOnDoubleClick={false}
                value={value}
                setValue={onChange}
                clockFormat='24-hour-clock'
                allowedDropdowns={[
                    'period',
                    'months',
                    'month-days',
                    'week-days',
                    'hours',
                    'minutes'
                ]}
                clearButton={false}
                allowClear={false}
                allowedPeriods={['month', 'week', 'day', 'hour', 'minute']}
                dropdownsConfig={{
                    // minutes: {
                    //     filterOption: ({ value }) => {
                    //         return value % 15 === 0
                    //     }
                    // }
                }}
            />
            <Text fontStyle='italic'>
                {cronstrue.toString(value, { use24HourTimeFormat: true })}
            </Text>
        </FormControl>
    )
}

export default CronWidget;
